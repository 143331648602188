<template>
    <div class="app-container">
        <div style="width:800px;margin:0 auto">
            <el-steps :active="active" align-center>
                <el-step title="基础信息"></el-step>
                <el-step title="经营信息" v-if="userType!='1'"></el-step>
                <el-step title="结算信息"></el-step>
                <el-step title="成本配置"></el-step>
                <!-- <el-step title="收益配置"></el-step>
                <el-step title="返现配置"></el-step> -->
            </el-steps>
        </div>
        <BaseInfo @change="getBaseInfo" :provinceList="provinceList" type="agent" v-show="activeType=='baseInfo'"></BaseInfo>
        <BusinessInfo @change="getBusinessInfo" v-if="userType!='1'" :legalName="legalName" v-show="activeType=='businessInfo'"></BusinessInfo>
        <SettleInfo @change="getSettleInfo" :provinceList="provinceList" :companyName="companyName" :legalName="legalName" v-show="activeType=='settleInfo'"></SettleInfo>
        <CostConfig @change="getCostConfig" v-show="activeType=='costConfig'"></CostConfig>
        <!-- <BenefitConfig @change="getBenefitConfig" :parentAgentNo="parentAgentNo" type="agent" v-show="activeType=='benefitConfig'"></BenefitConfig>
        <CashBackConfig @change="getCashBackConfig" :parentAgentNo="parentAgentNo" type="agent" v-show="activeType=='cashBackConfig'"></CashBackConfig> -->
        <el-dialog
            title="入网成功"
            :visible.sync="dialogVisible"
            width="40%"
            :show-close="false"
            :close-on-click-modal="false">
            <p>{{addTips}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import BaseInfo from '@/components/agentUpdateBase/baseInfo'
import BusinessInfo from '@/components/agentUpdateBase/businessInfo'
import SettleInfo from '@/components/agentUpdateBase/settleInfo'
import BenefitConfig from '@/components/agentUpdateBase/benefitConfig'
import CashBackConfig from '@/components/agentUpdateBase/cashBackConfig'
import CostConfig from '@/components/agentUpdateBase/costConfig'
import { BasicApi,AgentApi } from '@/api'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            active: 0,
            activeType: 'baseInfo',
            parentAgentNo: 0,
            legalName: '',
            companyName: '',
            params: {},
            userType: '',
            addTips:'',
            dialogVisible: false,
        }
    },
    components:{
        BaseInfo,
        BusinessInfo,
        SettleInfo,
        BenefitConfig,
        CashBackConfig,
        CostConfig
    },
    created() {
        this.userType = this.$route.query.userType
    },
    computed:{
        ...mapState('app', ['provinceCityArea']),
        provinceList(){
            return '' || this.provinceCityArea
        }
    },
    methods: {
        getBaseInfo(params,type){
            if(type=='next'){
                this.active ++
                this.activeType = this.userType =='1'? 'settleInfo':'businessInfo'
                this.params.baseInfo = params
                this.parentAgentNo = params.parentAgentNo ? params.parentAgentNo: '0'
                this.legalName = params.legalName
                this.companyName = params.legalName
            } if(type=='prev'){
                params.parentAgentNo = params.parentAgentNo == '0' ? '' :params.parentAgentNo
            }
            
        },
        getBusinessInfo(params,type){
            if(type=='next'){
                this.active ++
                this.activeType = 'settleInfo'
                this.params.businessInfo = params
                this.companyName = params.companyName
            } if(type=='prev'){
                this.active --
                this.activeType = 'baseInfo'
            }
        },
        getSettleInfo(params,type){
            if(type=='next'){
                this.active ++
                this.activeType = 'costConfig'
                this.params.setterInfo = params
            } if(type=='prev'){
                this.active --
                this.activeType = this.userType =='1'? 'baseInfo':'businessInfo'
            }
        },
        getBenefitConfig(params,type){
            if(type=='next'){
                this.active ++
                this.params.policyInfoList = params
            } if(type=='prev'){
                this.active --
            }
        },
        getCostConfig(params,type){
            if(type=='submit'){
                this.params.costConfig = params
                this.params.userType = this.userType
                AgentApi.saveAgent(this.params)
                    .then(res=>{
                        if(res.success){
                            this.addTips = res.data.tip
                            this.dialogVisible = true
                        } else{

                        }
                    })
            } if(type=='prev'){
                this.active --
                this.activeType = 'settleInfo'
            }
        },
        getCashBackConfig(params,type){
            if(type=='submit'){
                this.params.agentActivityInfoDtoList = params
                AgentApi.saveAgent(this.params)
                    .then(res=>{
                        if(res.success){
                            this.addTips = res.data.tip
                            this.dialogVisible = true
                        } else{ 
                        }
                    })
            } if(type=='prev'){
                this.active --
            }
        },
        handleClose() {
            this.dialogVisible = false
            this.$router.push({
                name:'agentManage'
            })
        }
    },
}
</script>