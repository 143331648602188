<template>
    <div>
        <el-row>
            <el-col :span="24" style="padding:30px; text-align:center;">分润默认最低值，返现默认最大值，可在代理查询界面修改</el-col>
        </el-row>
        <el-form ref="costInfoform" :model="costInfo" :rules="rules" label-width="160px">
            <el-form-item label="分润代付开关" prop="profitSwitch">
                <el-select v-model="costInfo.profitSwitch" placeholder="请选择">
                    <el-option label="开启" value="1" ></el-option>
                    <el-option label="关闭" value="0" ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="返现代付开关" prop="cashbackSwitch">
                <el-select v-model="costInfo.cashbackSwitch" placeholder="请选择">
                    <el-option label="开启" value="1" ></el-option>
                    <el-option label="关闭" value="0" ></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="收益配置" prop="agentCostTemplateNo">
                <el-select v-model="costInfo.agentCostTemplateNo" placeholder="请选择" style="margin-right:10px">
                    <el-option v-for="item in agentCostTemplateList" :key="item.id" :label="item.templateName" :value="item.templateNo" ></el-option>
                </el-select>
                <el-button @click="benefitDetail">详情</el-button>
                <el-button @click="benefitAdd">新增</el-button>
            </el-form-item>
            <el-form-item label="返现配置" prop="cashbackTemplateNo">
                <el-select v-model="costInfo.cashbackTemplateNo" placeholder="请选择" style="margin-right:10px">
                    <el-option v-for="item in agentActivityTemplateDoList" :key="item.id" :label="item.templateName" :value="item.templateNo" ></el-option>
                </el-select> 
                <el-button @click="cashbackDetail">详情</el-button>
                <el-button @click="cashbackAdd">新增</el-button>
            </el-form-item> -->
        </el-form>
        <el-row :gutter="20">
            <el-col :span="12" :offset="6">
                <el-button @click="prevClick">上一页</el-button>
                <el-button type="primary" v-if="active!=4" @click="nextClick('costInfoform')">提交</el-button>
            </el-col>
        </el-row>
        <BenefitDialog :on-fresh="showBenefitForm" @on-close="handlerBenefitFormOnclose" :templateNo="costInfo.agentCostTemplateNo" :isAdd="benefitIsAdd"></BenefitDialog>
        <CustomForm :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :templateNo="costInfo.cashbackTemplateNo" :isAdd="isAdd"></CustomForm>
    </div>
</template>
<script>
import { AgentApi } from '@/api'
import CustomForm from "@/views/myAgent/cashBackConfig/CustomForm";
import BenefitDialog from "@/components/benefitDialog/Index";
export default{
    data(){
        return{
            costInfo:{},
            agentActivityTemplateDoList:[],
            agentCostTemplateList:[],
            showBenefitForm: false,
            showCustomForm: false,
            rules:{
                profitSwitch: [{ required: true, message: '请选择分润代付开关', trigger: 'change' }],
                cashbackSwitch: [{ required: true, message: '请选择返现代付开关', trigger: 'change' }],
                // agentCostTemplateNo: [{ required: true, message: '请选择收益配置', trigger: 'change' }],
                // cashbackTemplateNo: [{ required: true, message: '请选择返现配置', trigger: 'change' }],
            },
            benefitIsAdd: false,
            isAdd: false,
        }
    },
    components:{
        CustomForm,
        BenefitDialog
    },
    created() {
        this.commitChange()
    },
    methods: {
        commitChange(){
            AgentApi.beforeNewAgent()
                .then(res=>{
                    if(res.success){
                        this.agentActivityTemplateDoList = res.data.agentActivityTemplateDoList
                        this.agentCostTemplateList = res.data.agentCostTemplateList
                    }
                })
        },
        benefitDetail(){
            if(this.costInfo.agentCostTemplateNo){
                this.benefitIsAdd = false
                this.showBenefitForm = true
            } else{
                this.Message('请选择收益配置')
            }
        },
        benefitAdd(){
            this.benefitIsAdd = true
            this.showBenefitForm = true
        },
        cashbackDetail(){
            if(this.costInfo.cashbackTemplateNo){
                this.isAdd = false
                this.showCustomForm = true
            } else{
                this.Message('请选择收益配置')
            }
        },
        cashbackAdd(){
            this.isAdd = true
            this.showCustomForm = true
        },
        handlerBenefitFormOnclose() {
            this.showBenefitForm = false;
            this.commitChange();
        },
        handlerCustomFormOnclose() {
            this.showCustomForm = false;
            this.commitChange();
        },
        prevClick(){
            this.$emit('change','','prev')
        },
        nextClick(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('change',this.costInfo,'submit')
                } else {
                    return false;
                }
            });
        },
    },
}
</script>
